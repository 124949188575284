import React from "react";
import "./Login.css";
import { FaUser } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import axios from "axios";
import FormData from "form-data";

const Login = () => {
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Username:', username);
        console.log('Password:', password);

        axios.get('https://api.deymon.dev/api/v1/login', {
            params: {
                username: username,
                password: password
            }
        }).then((response) => {
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        });
            
    }

    return (
        <div className='wrapper'>
            <form onSubmit={handleSubmit} action=''>
                <h1>Login</h1>
                <div className='input-box'>
                    <input value={username} onChange={(e) => setUsername(e.target.value)} type='username' id="username" placeholder='Username' required />
                    <FaUser className='icon' />
                </div>
                <div className='input-box'>
                    <input value={password} onChange={(e) => setPassword(e.target.value)} type='password' id="password" placeholder='Password' required />
                    <FaLock className='icon' />
                </div>

                <div className="remember-forgot">
                    <label>
                        <input type='checkbox' />
                        Remember me
                    </label>
                    <a href=''>Forgot password?</a>
                </div>

                <button type="submit">Login</button>

                <div className='register-link'>
                    <p>Don't have an account? <a href=''>Register here</a></p>
                </div>
            </form>
        </div>
    )
}

export default Login;
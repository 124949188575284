import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Login from './Components/Login/Login';

function App() {
  const [currentForm, setCurrentForm] = useState('login');
  return (
    <div>
      {currentForm === 'login' && <Login />}
    </div>
  );
}

export default App;
